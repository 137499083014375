













































































































































































































































import Vue from "vue";
import CategoryList from "@/components/CategoryList.vue";
export default Vue.extend({
  name: "About",
  components: {
    CategoryList,
  },
});
